<template>
  <div class="lesson-video-container">
    <!-- Main Video -->
    <div class="video-frame-container" v-if="videoLink">
      <iframe
        :key="videoLink"
        :src="videoLink"
        loading="lazy"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowfullscreen="true"
      ></iframe>
    </div>

    <!-- Thumbnails Section -->
    <div class="video-thumbnails">
      <div
        v-for="(video, index) in videos"
        :key="index"
        class="thumbnail-box"
        @click="showVideo(index)"
        :class="{ active: index === currentIndex }"
      >
        <img :src="getThumbnail(video)" alt="Video Thumbnail" class="thumbnail-image" />
      </div>
    </div>

    <!-- Next Video Button -->
    <button @click="nextVideo" class="btn-next">الفيديو التالي</button>

    <!-- Modal Component -->
    <custom-modal
      :visible="isModalOpen"
      :title="modalTitle"
      :message="modalMessage"
      @close="closeModal"
      @confirm="closeModal"
    />
  </div>
</template>

<script>
import apiService from '@/apiService';
import CustomModal from './CustomModal.vue';

export default {
  name: 'LessonVideo',
  components: { CustomModal },
  data() {
    return {
      videoLink: '',
      videos: [],
      currentIndex: 0,
      isModalOpen: false,
      modalTitle: '',
      modalMessage: '',
    };
  },
  mounted() {
    const lessonId = this.$route.params.lesson_id;
    this.getVideos(lessonId);
  },
  methods: {
    async getVideos(lessonId) {
      try {
        const response = await apiService.getVideos(lessonId);
        if (response.data && response.data.data) {
          this.videos = response.data.data;
          this.showVideo(0);
        } else {
          console.error('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    },
    showVideo(index) {
      if (this.videos[index]) {
        const lesson = this.videos[index];
        this.videoLink = this.formatBunnyLink(lesson.link);
        this.currentIndex = index;
      }
    },
    nextVideo() {
      if (this.currentIndex < this.videos.length - 1) {
        this.currentIndex++;
        this.showVideo(this.currentIndex);
      } else {
        this.modalMessage = 'لا توجد فيديوهات أخرى';
        this.openModal();
      }
    },
    getThumbnail(video) {
      if (video.image) {
        return video.image.original_url;
      }
      return 'default-thumbnail.jpg';
    },
    formatBunnyLink(link) {
      // تعديل الرابط ليستخدم تنسيق Bunny embed
      const idMatch = link.match(/\/play\/(\d+)\/([\w-]+)/);
      if (idMatch) {
        const [, libraryId, videoId] = idMatch;
        return `https://iframe.mediadelivery.net/embed/${libraryId}/${videoId}?autoplay=true`;
      }
      return link; // إذا لم يكن رابط Bunny، استخدمه كما هو
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.lesson-video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 7vw;
}

/* ✅ تعديل الحاوية لعرض الفيديو بتنسيق Bunny.net */
.video-frame-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* نسبة العرض إلى الارتفاع 16:9 */
  margin-bottom: 20px;
}

.video-frame-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-thumbnails {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 10px;
  scrollbar-width: none;
  flex-wrap: wrap;
}

.thumbnail-box {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  width: 206px;
  height: 119px;
  border: 3px solid transparent;
  transition: border-color 0.3s ease;
}

.thumbnail-box.active {
  border-color: #faaf43;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.btn-next {
  padding: 10px 45px;
  background-color: #faaf43;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 15px;
  cursor: pointer;
}
</style>
